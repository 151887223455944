import { Link as RouterLink } from 'react-router-dom';
import { Text, Link, HTMLChakraProps, LinkProps, Flex, Image } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import colors from '../../themes/colors';
import Home from '../../assets/home-gray.svg';
import Spinner from '../../assets/spinner-gray.svg';
import Wallet from '../../assets/wallet-gray.svg';

export interface NLinkProps extends HTMLChakraProps<'p'> {
  path: string;
  title: string;
  active?: boolean;
  bordered?: boolean;
  linkProps?: LinkProps;
  icon?: string;
}

const NLink: FC<NLinkProps> = (props) => {
  const { t } = useTranslation();
  const { path, title, icon, active = false, bordered = false, linkProps, ...restStyles } = props;

  const borderBottom = {
    content: '" "',
    height: 1,
    width: '100%',
    borderRadius: 3,
    position: 'absolute',
    backgroundColor: colors.primary,
    left: '50%',
    bottom: -2,
    transform: 'translate(-50%, -50%)',
  };

  return (
    <Flex>
      <Image src={path === '/' ? Home : path.includes('pools') ? Spinner : Wallet} mr="1" />
      <Text
        fontSize={{ base: 14, md: 18, lg: 20 }}
        _hover={{
          color: colors.primary,
        }}
        position="relative"
        {...restStyles}
      >
        <Link
          as={RouterLink}
          key={title}
          to={path}
          color={colors.textPrimary}
          _after={active && bordered ? borderBottom : {}}
          {...linkProps}
        >
          {t(title)}
        </Link>
      </Text>
    </Flex>
  );
};

export default NLink;
