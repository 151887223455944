import { ComponentStyleConfig } from '@chakra-ui/react';
import colors from '../colors';

const Toast: ComponentStyleConfig = {
  baseStyle: {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    bg: colors.tipBg,
  },
  variants: {
    solid: {},
  },
};

export default Toast;
