import { ComponentStyleConfig } from '@chakra-ui/react';
// import colors from '../colors';

const Modal: ComponentStyleConfig = {
  baseStyle: {},
  variants: {},
  defaultProps: {},
};

export default Modal;
