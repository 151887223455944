import React, { useEffect } from 'react';
import { Flex, Box, Skeleton } from '@chakra-ui/react';

import { queryCardByConfig, getConnectedState } from '../../api/query';
import CardItem from './cardItem';
import accountStore from '../../stores/account';

const Cards = (props: any) => {
  const { config = [], cb = null } = props;
  const { points: currentUserPoints } = accountStore.useState('points');
  const { data = {}, refetch } = queryCardByConfig(config);
  const { cards = [] } = data;

  // useEffect(() => {
  //   setInterval(() => {
  //     refetch();
  //   }, 10000);
  // }, []);

  return (
    <Flex wrap={{ base: 'nowrap', md: 'wrap' }} justify="flex-start" overflow="scroll">
      {cards.map((card: any, idx: number) => {
        return (
          <Box
            width={{ base: '320px', md: '50%', lg: '33.333%', '2xl': '25%' }}
            minW={{ base: '320px', md: 0 }}
            key={idx}
          >
            <Box p={2}>
              <CardItem
                {...card}
                key={idx}
                currentUserPoints={currentUserPoints}
                isRedeem
                cb={() => {
                  refetch();
                  if (cb) {
                    cb();
                  }
                }}
              />
            </Box>
          </Box>
        );
      })}
    </Flex>
  );
};

export default Cards;
