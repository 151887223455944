import React from 'react';
import { Container } from '@chakra-ui/react';
// import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
  title?: string;
  minH?: number;
}

const Layout = ({ children, minH }: Props) => {
  // const { t } = useTranslation();
  return (
    <Container
      maxW={{ base: '100%', lg: 900, xl: 1100, '2xl': 1300 }}
      minH={minH}
      py={{ base: 0, lg: 4 }}
    >
      {children}
    </Container>
  );
};

export default Layout;
