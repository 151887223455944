import React, { FC } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { t } from '../../i18n';

// const DefaultImage = <Box height={233} width={188} backgroundColor={colors.divider.dark} ></Box>;

const ConnectTip = (props: any) => {
  return (
    <Box textAlign="center">
      <Center>
        <Text
          fontSize={{ base: '18px', md: '24px', lg: '28px', xl: '32px' }}
          textColor="#CCCCCC"
          fontWeight="bold"
          mt={4}
        >
          {t('connect.tip').toUpperCase()}
        </Text>
      </Center>
    </Box>
  );
};

export default ConnectTip;
