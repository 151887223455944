import React, { FC, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  useBreakpointValue,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react';
import { globalStore } from 'rekv';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TriangleDownIcon } from '@chakra-ui/icons';
import ReactGA from 'react-ga';

import CommLayout from 'layouts/common';
import NavLink from '../navlink';
import Web3Com from '../web3';
import themes from '../../themes';
import logo from '../../assets/logo.svg';
import logoS from '../../assets/logo-s.svg';

// const formatAddress = (addr: string) => `${addr.slice(0, 4)}...${addr.slice(-4)}`;

const HeaderContent: FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const logoImg = useBreakpointValue({ base: logoS, md: logo });
  const history = useHistory();
  const { account, api } = globalStore.useState('account', 'api');
  // const { t } = useTranslation();
  useEffect(() => {
    return () => {
      // cleanup
    };
  }, [account?.address, api]);

  const handleOnOpen = () => {
    onOpen();
  };

  return (
    <Flex justify="space-between" px={4} py={{ base: 1, md: 2, lg: 3 }}>
      <Box w={{ base: '112px', md: '204px' }}>
        <Flex
          h="100%"
          algin="center"
          onClick={() => {
            history.push('/');
          }}
        >
          <Image w={{ base: '32px', md: '80px', lg: '100px' }} h="auto" src={logoImg} />
        </Flex>
      </Box>

      <Flex justify="center" align="center" mr={{ base: 2, md: 0 }}>
        <NavLink />

        {/*
        <Menu isOpen={isOpen}>
          <MenuButton
            fontSize={{ base: 14, md: 18, lg: 20 }}
            fontWeight="bold"
            textColor="textPrimary"
            onClick={() => handleOnOpen()}
            onMouseLeave={() => onClose()}
            onMouseEnter={() => handleOnOpen()}
          >
            {t('inventory')}
          </MenuButton>
          <MenuList
            onMouseEnter={() => handleOnOpen()}
            onMouseLeave={() => onClose()}
            minWidth={{ base: '84px', md: '104px', lg: '114px' }}
            marginLeft={'-4px'}
          >
            <MenuItem
              fontSize={{ base: 12, md: 14, lg: 16 }}
              textColor="textPrimary"
              fontWeight="bold"
              display="block"
              textAlign="center"
              onClick={() => {
                ReactGA.event({
                  category: 'menu',
                  action: `jump to perks`,
                });
                history.push('/perks');
              }}
            >
              {t('perks')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              fontSize={{ base: 12, md: 14, lg: 16 }}
              textColor="textPrimary"
              fontWeight="bold"
              display="block"
              textAlign="center"
              onClick={() => {
                ReactGA.event({
                  category: 'menu',
                  action: `jump to trophies`,
                });
                history.push('/trophies');
              }}
            >
              {t('trophies')}
            </MenuItem>
          </MenuList>
          <TriangleDownIcon ml={1} w="10px" h="10px" color="textPrimary" />
        </Menu> */}
      </Flex>
      <Box>
        <Web3Com />
      </Box>
    </Flex>
  );
};

export default HeaderContent;
