import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import OwnedList from '../../components/cards/ownedList';
import { getConnectedState } from '../../api/query';
import ConnectTip from '../../components/connectTip';
import Title from '../../components/title';
import Panel from '../../components/panel';

const Page = () => {
  const connected = getConnectedState();
  useEffect(() => {
    ReactGA.pageview('/perks');
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Title desc={t('perks.desc')} />
      {connected ? <OwnedList isPerks /> : <ConnectTip />}
    </>
  );
};

export default Page;
