import React, { useState, useEffect } from 'react';
import { Box, Image, AspectRatio, useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import homeBanner from '../../assets/banner1.jpg';
import otherBanner from '../../assets/banner2.jpg';

const Banner = () => {
  const location = useLocation();
  const routePath = location.pathname;
  const [banner, setBanner] = useState('');
  const [isFullBanner] = useMediaQuery(['(max-width: 992px)']);

  useEffect(() => {
    if (routePath === '/') {
      setBanner(homeBanner);
    } else {
      setBanner(otherBanner);
    }
  }, [routePath]);

  return (
    <Box position="relative" top={isFullBanner ? 0 : '-12px'}>
      <AspectRatio ratio={{ base: 3 / 1, lg: 4 / 1 }}>
        <Image src={banner} alt="DKP banner" />
      </AspectRatio>
    </Box>
  );
};

export default Banner;
