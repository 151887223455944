import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import Title from '../../components/title';
import Panel from '../../components/panel';
import OwnedList from '../../components/cards/ownedList';
import { getConnectedState } from '../../api/query';
import ConnectTip from '../../components/connectTip';

// import {

// } from '../../api/contracts';
// import { useAssetsQuery } from '../../api/query';

const Page = () => {
  const { t } = useTranslation();
  const connected = getConnectedState();

  useEffect(() => {
    ReactGA.pageview('/trophies');
  }, []);

  return (
    <>
      <Title desc={t('trophies.desc')} />
      {connected ? <OwnedList /> : <ConnectTip />}
    </>
  );
};

export default Page;
