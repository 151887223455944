import Rekv from 'rekv';

const pool = new Rekv({
  pools: {
    0: {
      id: 0,
      name: 'donate OVO',
      token: 'JTT',
    },
    1: {
      id: 1,
      name: 'stake pool',
      token: 'JTT',
    },
    2: {
      id: 2,
      name: 'Wins pool',
      token: '',
    },
  },
});

export default pool;
