import React from 'react';
import { Flex, Box, Text, useTheme } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { t } from '../../i18n';

const Title = (props: any) => {
  const { title, desc = null, token, pointName, showLink = null } = props;
  const { colors } = useTheme();

  return (
    <Flex justify="space-between">
      <Box>
        <Text
          as="div"
          fontSize={{ base: '16px', lg: '18px' }}
          color={colors.textPrimary}
          fontWeight="bold"
          fontFamily="Eurostile"
        >
          {title}
        </Text>
        {desc && (
          <Text
            as="div"
            fontSize={{ base: '11px', lg: '12px' }}
            color={colors.textTips}
            fontFamily="HelveticaNeue"
          >
            {desc}
          </Text>
        )}
        {token && pointName && (
          <Text as="div" fontSize={{ base: '11px', lg: '12px' }} color={colors.textTips}>
            {t('stake')} <Text as="b">{token}</Text> {t('for')} <Text as="b">{pointName}</Text>
          </Text>
        )}
        {!token && pointName && (
          <Text as="div" fontSize={{ base: '11px', lg: '12px' }} color={colors.textTips}>
            {t('earn')} <Text as="b">{pointName}</Text> {t('by')}
          </Text>
        )}
      </Box>
      {showLink && (
        <Box mr={4} fontSize={14} textColor="textLink" fontWeight={400} cursor="pointer">
          <Text onClick={() => showLink()}>
            {t('show.all')}
            <ChevronRightIcon />
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default Title;
