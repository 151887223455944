import React, { useState, useEffect } from 'react';
import {
  Button,
  Badge,
  VStack,
  HStack,
  Flex,
  Box,
  Text,
  Image,
  Center,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import ReactGA from 'react-ga';

import { getConnectedState } from '../../api/query';

import { ToastProps } from '../../utils/types';
import { dkpoolApi } from '../../api/contracts';

import { timeformater, toast, formatBalance } from '../../utils';
import poolStore from '../../stores/pools';

const CardItem = (props: any) => {
  const dkpool = dkpoolApi();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const connected = getConnectedState();
  const [canRedeem, setCanRedeem] = useState(false);
  const [affordable, setAffordable] = useState(true);
  const [btnTip, setBtnTip] = useState('');
  const {
    name,
    DKP = 0,
    maxSupply,
    totalSupply,
    image,
    releaseTime = 0,
    closeTime = 0,
    poolId,
    cardId,
    cb = null,
    expandPools = [],
    expandSpendingDKP = [],
    currentUserPoints = {},
    isRedeem = false,
    redeemCount,
  } = props;
  const { pools } = poolStore.useState('pools');
  const dkpArr: any = [];
  expandPools.map((id: number, idx: number) => {
    dkpArr.push({ id, balance: expandSpendingDKP[idx] });
    return null;
  });
  dkpArr.push({ id: poolId, balance: DKP });

  // const expire = getNowTimestamp() > endTime;
  const getRedeemStatus = () => {
    if (redeemCount >= 1) {
      setCanRedeem(false);
      setBtnTip('redeemed');
      return;
    }

    if (expandPools.length === 0) {
      setCanRedeem(affordable);
    } else {
      const flag = dkpArr.some((pool: any, idx: number) => {
        const { id, balance } = pool;
        const bal = formatBalance(balance);
        return Number(currentUserPoints[id]) < Number(bal);
      });
      setCanRedeem(!flag);
    }
  };

  const redeemContainer = () => {
    console.log('redeem container');
  };

  // time stamp determine
  useEffect(() => {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);
    let flag = true;
    if (releaseTime && timestamp < releaseTime) {
      flag = false;
    }
    if (closeTime && timestamp > closeTime) {
      flag = false;
    }
    setCanRedeem(flag);
  }, [releaseTime, closeTime]);

  useEffect(() => {
    const flag = Number(currentUserPoints[poolId]) >= Number(formatBalance(DKP));
    setAffordable(flag);
  }, [currentUserPoints[poolId], currentUserPoints]);

  useEffect(() => {
    getRedeemStatus();
  }, [currentUserPoints[poolId], currentUserPoints]);

  useEffect(() => {
    if (redeemCount >= 1) {
      setCanRedeem(false);
      setBtnTip('redeemed');
    }
  }, [redeemCount]);

  const renderDKPS = () => {
    const lable = 'DKP';

    return (
      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        mb={2}
        overflow="hidden"
        h="42px"
        w="100%"
      >
        {dkpArr.map((pool: any, idx: number) => {
          const { id, balance } = pool;
          const bal = formatBalance(balance, 18);
          const afford = Number(currentUserPoints[id]) >= Number(bal);
          return (
            <HStack w="100%" justify="space-between">
              <Center>
                <Tag
                  size="sm"
                  maxW="78px"
                  key={idx}
                  borderRadius="full"
                  variant="solid"
                  bgColor="primary"
                  minH="16px"
                >
                  <ArrowForwardIcon
                    boxSize="12px"
                    borderRadius="full"
                    bgColor="white"
                    color="primary"
                    marginLeft="-6px"
                  />
                  <TagLabel ml={1} fontSize={10} fontWeight="bold">{`${lable}${
                    Number(id) + 1
                  }`}</TagLabel>
                </Tag>

                <Box
                  ml={2}
                  as="span"
                  fontSize="14px"
                  textColor={afford ? 'textPrimary' : 'error'}
                  fontWeight="bold"
                >
                  <NumberFormat value={bal} displayType={'text'} thousandSeparator={true} />
                </Box>
              </Center>
              <Text
                maxW="100px"
                textAlign="right"
                fontSize={10}
                fontWeight={400}
                textColor="#CCCCCC"
              >
                {pools[id].name}
              </Text>
            </HStack>
          );
        })}
      </Flex>
    );
  };

  const redeem = async () => {
    ReactGA.event({
      category: 'redeem',
      action: 'redeem cards',
    });

    try {
      if (!connected) return null;
      const toastProps: ToastProps = {
        title: 'Transaction',
        desc: '',
        status: 'success',
      };

      setLoading(true);
      const cbs = {
        receipt: () => {
          setLoading(false);
          if (cb) cb();
          toastProps.desc = t('trx.success');
          toast(toastProps);
          ReactGA.event({
            category: 'redeem',
            action: 'redeem cards successful',
            value: cardId,
          });
        },
        error: () => {
          setLoading(false);
        },
      };
      await dkpool.redeem(poolId, cardId, cbs);
      return null;
    } catch (error) {
      const toastProps = {
        title: '',
        desc: '',
        duration: 6000,
        status: 'info',
      };
      toastProps.title = 'Error';
      toastProps.desc = error.message;
      toastProps.status = 'error';
      toast(toastProps);
      setLoading(false);
      return null;
    }
  };

  if (!pools[poolId]) {
    return null;
  }
  return (
    <VStack
      pos="relative"
      h={{ base: '448px', md: '488px' }}
      border="1px solid #EEEEEE"
      maxW="320px"
      minW="262px"
      boxShadow="0px 4px 6px rgba(123, 70, 0, 0.1)"
      rounded="md"
      overflow="hidden"
      fontFamily="Eurostile"
    >
      <Center
        pos="relative"
        w="100%"
        maxH={{ base: '320px', md: '345px' }}
        h="100%"
        objectFit="cover"
        bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
      >
        <Image maxW="96%" src={image} />
        <Badge
          pos="absolute"
          right={3}
          top={3}
          borderRadius="20px"
          fontSize={10}
          bgColor="primary"
          textColor="white"
        >{`${maxSupply - totalSupply}/${maxSupply}`}</Badge>
      </Center>
      <Box
        pos="absolute"
        bottom={0}
        w="100%"
        textColor="textPrimary"
        bgColor="white"
        borderRadius="0px 0px 8px 8px"
        px={2.5}
      >
        <Box textAlign="left" px="5px">
          <Text fontSize={16} fontWeight="bold" mt={1}>
            {name}
          </Text>
          <Flex justifyContent="space-between">
            {expandPools.length === 0 ? (
              <Flex flexDirection="column" justifyContent="flex-end" mb={2} h="42px" w="100%">
                <HStack w="100%" justify="space-between">
                  <Center>
                    <Tag
                      size="sm"
                      maxW="78px"
                      borderRadius="full"
                      variant="solid"
                      bgColor="primary"
                      minH="16px"
                    >
                      <ArrowForwardIcon
                        boxSize="12px"
                        borderRadius="full"
                        bgColor="white"
                        color="primary"
                        marginLeft="-6px"
                      />
                      <TagLabel ml={1} fontSize={10} fontWeight="bold">{`DKP${
                        Number(poolId) + 1
                      }`}</TagLabel>
                    </Tag>

                    <Box
                      ml={2}
                      as="span"
                      fontSize="14px"
                      textColor={affordable ? 'textPrimary' : 'error'}
                      fontWeight="bold"
                    >
                      <NumberFormat
                        value={formatBalance(DKP)}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </Box>
                  </Center>
                  {isRedeem && (
                    <Text
                      maxW="100px"
                      textAlign="right"
                      fontSize={10}
                      fontWeight={400}
                      textColor="#CCCCCC"
                      fontFamily="Arial"
                    >
                      {pools[poolId].name}
                    </Text>
                  )}
                </HStack>
              </Flex>
            ) : (
              renderDKPS()
            )}
          </Flex>
        </Box>
        <Box
          w="100%"
          h="70px"
          onClick={() => redeemContainer()}
          // bottom={-45} left={0} px={2.5} pos='absolute'
        >
          <Button
            w="100%"
            fontSize={18}
            disabled={!affordable || !canRedeem || totalSupply === maxSupply}
            onClick={() => redeem()}
            isLoading={loading}
          >
            {btnTip
              ? t(btnTip).toUpperCase()
              : t(totalSupply === maxSupply ? 'soldout' : 'redeem').toUpperCase()}
          </Button>
          <Text fontSize={10} textColor="textTips" textAlign="center" m={2} fontFamily="Arial">
            {`${releaseTime > 0 ? timeformater(releaseTime) : ''} ~ ${
              closeTime > 0 ? timeformater(closeTime) : ''
            }`}
            {/* {affordable.toString()}   {canRedeem.toString()}
            {cardId.toString()}   {maxSupply.toString()} */}
          </Text>
        </Box>
      </Box>
    </VStack>
  );
};

export default CardItem;
