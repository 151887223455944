import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Web3Com from '../web3';

import Home from '../../assets/home-bottom.svg';
import Spinner from '../../assets/spinner-bottom.svg';
import Wallet from '../../assets/wallet-bottom.svg';
import HomeActive from '../../assets/home-active.svg';
import SpinnerActive from '../../assets/spinner-active.svg';
import WalletActive from '../../assets/wallet-active.svg';

const BottomHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const routePath = location.pathname;

  return (
    <Flex
      as="header"
      position="fixed"
      bottom={0}
      width="100%"
      height="64px"
      zIndex={1000}
      background="#fff"
      boxShadow="0px 5px 20px rgba(0, 0, 0, 0.16);"
      justifyContent="space-between"
      paddingTop="12px"
      fontFamily="Eurostile"
    >
      <Box w="25%">
        <Link to={'/'}>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Image src={Home} width="24px" display={routePath === '/' ? 'none' : 'block'} />
            <Image src={HomeActive} width="24px" display={routePath === '/' ? 'block' : 'none'} />
            <Text fontSize="12px" color={routePath === '/' ? 'primary' : 'textPrimary'}>
              {t('nav.home')}
            </Text>
          </Flex>
        </Link>
      </Box>
      <Box w="25%">
        <Link to={'/pools'}>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            color="textPrimary"
          >
            <Image src={Spinner} width="24px" display={routePath === '/pools' ? 'none' : 'block'} />
            <Image
              src={SpinnerActive}
              width="24px"
              display={routePath === '/pools' ? 'block' : 'none'}
            />
            <Text fontSize="12px" color={routePath === '/pools' ? 'primary' : 'textPrimary'}>
              {t('nav.pools')}
            </Text>
          </Flex>
        </Link>
      </Box>
      <Box w="25%">
        <Link to={'/wallet'}>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            color="textPrimary"
          >
            <Image src={Wallet} width="24px" display={routePath === '/wallet' ? 'none' : 'block'} />
            <Image
              src={WalletActive}
              width="24px"
              display={routePath === '/wallet' ? 'block' : 'none'}
            />
            <Text fontSize="12px" color={routePath === '/wallet' ? 'primary' : 'textPrimary'}>
              {t('nav.wallet')}
            </Text>
          </Flex>
        </Link>
      </Box>
      <Box w="25%">
        <Web3Com variant={'compact'} />
      </Box>
    </Flex>
  );
};

export default BottomHeader;
