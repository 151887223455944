import React from 'react';
import { Box } from '@chakra-ui/react';

const Panel = (props: any) => {
  const { children, ...rest } = props;
  return (
    <Box
      p="4"
      border="4px solid rgba(71, 181, 237, 0)"
      background="linear-gradient(180deg, #FFFFFE 0%, #FFFCFC 100%)"
      boxShadow="md"
      rounded="md"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Panel;
