import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import TokenItem from '../tokenItem';
import ConnectTip from '../connectTip';

import { getConnectedState, getAddrState, useMyBalInfo, useUserRiceMainnet } from '../../api/query';
import { formatBalance } from '../../utils';
import { getChainData } from '../../utils/utilities';

const getNetwork = () => {
  return getChainData(+window.ethereum.chainId).network;
};

const Tokens = () => {
  const connected = getConnectedState();

  // get user RICE balance in mainnet
  const addr = getAddrState();
  const { data = [] } = useUserRiceMainnet(addr);
  const [balanceMainnet, setBalanceMainnet] = useState('0');
  useEffect(() => {
    if (data.length > 0) {
      setBalanceMainnet(data[0].balances[0].value);
    }
  }, [data]);

  // get user RICE balance in current network
  const curNetwork = getNetwork();
  const { data: dataX = {} } = useMyBalInfo('RICE');
  const { bal = '0' } = dataX;

  return (
    <Box>
      {connected && (
        <>
          <TokenItem network="Mainnet" balance={formatBalance(balanceMainnet)} />
          {curNetwork !== 'Mainnet' && (
            <TokenItem network={curNetwork} balance={formatBalance(bal)} />
          )}
        </>
      )}
      {!connected && <ConnectTip />}
    </Box>
  );
};

export default Tokens;
