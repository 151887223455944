import React, { useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import { useOVOInfo, getConnectedState, useMyPoints } from '../../api/query';
import { OVO_POOL_ID } from '../../config/constants';
import Title from '../title';
import Panel from '../panel';
import PoolItem from '../poolItem';
import DonateModal from '../donateModal';

import poolIcon from '../../assets/donate_icon.jpg';
import xdaiIcon from '../../assets/xdai.svg';

import store from '../../stores/modal';
import accountStore from '../../stores/account';

const OVOPannel = () => {
  // const { appState = {} } = globalStore.useState('appState')
  // const { connected = false } = appState
  const connected = getConnectedState();
  const { t } = useTranslation();
  const { data = {}, refetch } = useOVOInfo(OVO_POOL_ID);
  const { ovoBal = '0', userPoints = '0', poolInfo = {} } = data;
  const { points } = accountStore.useState('points');

  useEffect(() => {
    accountStore.setState({
      points: {
        ...points,
        [OVO_POOL_ID]: userPoints,
      },
    });
  }, [userPoints]);

  const handleModalOpen = () => {
    ReactGA.modalview('/donate');
    store.setState({ donateModalShow: true });
  };

  const props = {
    poolInfo: {
      ...poolInfo,
      id: OVO_POOL_ID,
      icon: poolIcon,
      title: 'DKP1',
      desc: (
        <>
          <Text>
            {t('donate')}
            <Text as="b"> JTT</Text> in OVO Grants to get <Text as="b">DKP1</Text>
          </Text>
        </>
      ),
    },
    token: {
      name: 'JTT',
      icon: xdaiIcon,
      balance: ovoBal,
    },
    pointBal: userPoints,
  };

  return (
    <Panel py={4} mb={{ base: 2, lg: 4 }}>
      <Title title={t('ovo.title')} desc={t('ovo.desc')}></Title>
      <PoolItem
        {...props}
        mt={{ base: 2, lg: 4 }}
        mx="-16px"
        isDonate
        userPoints={userPoints}
        openModal={() => connected && handleModalOpen()}
        cb={() => refetch()}
      />
      {connected && <DonateModal tokenName={'JTT'} {...props} cb={() => refetch()} />}
    </Panel>
  );
};

export default OVOPannel;
