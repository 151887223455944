import * as React from 'react';
import { HashRouter, Switch, Route, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { globalStore } from 'rekv';
import {
  ChakraProvider,
  CSSReset,
  Box,
  Flex,
  LightMode,
  DarkMode,
  Container,
} from '@chakra-ui/react';
import ReactGA from 'react-ga';
import CommLayout from 'layouts/common';
import Home from './pages/home';
import Pools from './pages/pools';
import Wallet from './pages/wallet';
import NotFound from './pages/404';
import Header from './components/header';
import Banner from './components/banner';
import Footer from './components/footer';
import TranslateHOC from './components/translate';
import Fonts from './themes/Fonts';
import theme from './themes';
import './i18n';

import { queryClient, useConfig } from './api/query';
import { scrollTo } from './utils/ui';
import poolStore from './stores/pools';

const ControlledLayout = () => {
  const location = useLocation();
  // Scroll on top when route changes
  React.useEffect(() => {
    scrollTo(0);
    const ref = new URLSearchParams(location.search).get('ref');
    if (ref) {
      ReactGA.event({
        category: `Refer`,
        action: `Refer page open with ${ref}`,
      });
    }
  }, [location.pathname, location.search]);

  // google ga
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOLE_GA);
    ReactGA.pageview(window.location.pathname);
  }, []);
  const { data = {} } = useConfig();
  const { poolConfig = [], redeemConfig = {}, disableCards = [] } = data;
  globalStore.setState({ poolConfig, redeemConfig, disableCards });
  const { pools = {} } = poolStore.useState('pools');
  React.useEffect(() => {
    poolConfig.map((pool: any) => {
      const { id, token, name } = pool;
      pools[id] = { id, name, token: token.name };
      return null;
    });
    poolStore.setState({ pools });
  }, [poolConfig]);

  return (
    <LightMode>
      <Flex
        justifyContent="center"
        alignItems="center"
        overflowX="hidden"
        bg="background: linear-gradient(180deg, #FCFFFF 0%, #FFFDF7 100%)"
      >
        <Box w="100%">
          <Container maxW={{ base: '100%', lg: 900, xl: 1100, '2xl': 1300 }}>
            <Header sticky />
            <Box h={{ base: 0, md: '56px', lg: '64px' }}></Box>
            <Banner />
          </Container>
          <Box position="relative" top={{ base: 0, lg: '-36px' }}>
            <CommLayout minH={300}>
              <Switch>
                <Route exact strict path="/" component={Home} />
                <Route exact strict path="/pools" component={Pools} />
                <Route exact strict path="/wallet" component={Wallet} />
                <Route exact strict path="*" component={NotFound} />
              </Switch>
            </CommLayout>
          </Box>
          <Footer />
          <Box h={{ base: '64px', md: 0 }}></Box>
        </Box>
      </Flex>
    </LightMode>
  );
};

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <Fonts />
    <ChakraProvider theme={theme}>
      <HashRouter>
        <TranslateHOC>
          <ControlledLayout />
        </TranslateHOC>
      </HashRouter>
    </ChakraProvider>
  </QueryClientProvider>
);

export default {};
