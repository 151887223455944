import React from 'react';
import { useTranslation } from 'react-i18next';
import { globalStore } from 'rekv';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getConnectedState } from '../../api/query';
import Title from '../title';
import Panel from '../panel';
import PoolList from '../poolList';
import ConnectTip from '../connectTip';

const StakePannel = (props: any) => {
  const { isSlice = false, ...rest } = props;
  const connected = getConnectedState();
  const history = useHistory();
  const { t } = useTranslation();
  const { poolConfig } = globalStore.useState('poolConfig');

  return (
    <Panel py={4} {...rest}>
      <Title
        title={t('task')}
        desc={t('stake.desc')}
        showLink={
          isSlice
            ? () => {
                ReactGA.event({
                  category: 'stake',
                  action: 'click show all staking pool',
                });
                history.push('/pools');
              }
            : null
        }
      ></Title>
      {connected ? <PoolList isSlice={isSlice} pools={poolConfig} /> : <ConnectTip />}
    </Panel>
  );
};

export default StakePannel;
