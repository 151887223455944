import React, { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { globalStore } from 'rekv';

import { useUserNFT, getAddrState, queryCardsWithPoolId } from 'api/query';

import WinsItem from './winsItem';
import accountStore from '../../stores/account';
import { WINS_POOL_ID } from '../../config/constants';

const Roles = (props: any) => {
  const { id } = props;
  const addr = getAddrState();
  const { points } = accountStore.useState('points');
  const cardsRes = useUserNFT(addr);
  const { disableCards = [] } = globalStore.useState('disableCards');

  const userCards = cardsRes.data?.cards || [];

  // TODO add user balance query
  const { data = {}, refetch } = queryCardsWithPoolId(WINS_POOL_ID, true);
  let { cards = [] } = data;

  cards = cards.filter((card: any) => {
    if (disableCards.indexOf(Number(card.cardId)) >= 0) {
      return false;
    }
    return true;
  });
  useEffect(() => {
    setInterval(() => refetch(), 60000);
  }, [userCards]);

  return (
    <Box borderRadius="0px 0px 12px 12px">
      <Flex wrap={{ base: 'nowrap', md: 'wrap' }} justify="flex-start" overflow="scroll">
        {cards.map((card: any, idx: number) => {
          return (
            <Box width={{ base: '320px', md: '50%' }} minW={{ base: '320px', md: 0 }} key={idx}>
              <WinsItem
                currentUserPoints={points}
                userCards={userCards}
                {...card}
                key={idx}
                cb={() => {
                  refetch();
                  cardsRes.refetch();
                }}
              />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Roles;
