/* eslint-disable */
export const NAV_MAP: Record<string, string> = {
  'nav.home': '/',
  'nav.pools': '/pools',
  'nav.wallet': '/wallet',
};

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_TESTNET = 97,
  XDAI = 100,
}

export const SupportedChainId = [100, 4]; // xdai and rinkeby

// OVO funds
export const OVO: any = {
  [ChainId.XDAI]: '0x0666D5b6D079aE949ED4E36089428aD3f28DaEa6',
  [ChainId.RINKEBY]: '0x6ae12905a530fcae02C5b7E84569Ffb0Be42a368',
};

export const RICE: any = {
  [ChainId.XDAI]: '0x97Edc0e345FbBBd8460847Fcfa3bc2a13bF8641F',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

export const MUSEX: any = {
  [ChainId.XDAI]: '',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

// tt0
export const WXDAI: any = {
  [ChainId.XDAI]: '0x1AC46F3af786f6193F2bB5E6E8F8393C1620a7ac',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

export const JTT: any = {
  [ChainId.XDAI]: '0x72E6cF607A3FF0e823Bf02f6725e76EcbC0A27bD',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

export const ATT: any = {
  [ChainId.XDAI]: '0x0995549dd369503f7d9753ccc88c3428DB92C6FA',
  [ChainId.RINKEBY]: '0x3291548496A243096d8BB0Bd980369727d8dFA32',
};

export const DKPOOL: any = {
  [ChainId.XDAI]: '0x2314D4e06e069d53b7e34b337c160FDef06d0425',
  [ChainId.RINKEBY]: '0xc9Ddfeaf2a2B0Ac1684C61f28A63c904C33501A2',
};

export const NFTS: any = {
  [ChainId.XDAI]: '0x2DbA30A53f3987B87A1788b3a7437909b5A7bEbD',
  [ChainId.RINKEBY]: '0xC6D7EF477773540aD47bB2e9718ffe90c52978d0',
};

export const TOKEN_ADDRESS: { [key: string]: any } = {
  WXDAI,
  RICE,
  MUSEX,
  JTT,
  ATT,
};

export const POINT_PRECISION = 100000000;
export const TOKEN_PERCISION = 1000000000000000000;
// approve amount 1b
export const APPROVE_LIMIT = '100000000000000000000000000';

// stake limit
export const MAX_STAKE = 10000000000;
export const MIN_STAKE = 0.1;

// donate limit
export const MAX_DONATE = 1000000;
export const MIN_DONATE = 1;

// Wins pool
export const WINS_POOL_ID = 2;
export const OVO_POOL_ID = 0;

// switch network
export const CHAIN_CONFIGS = {
  100: {
    id: '1',
    jsonrpc: '2.0',
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x64',
        chainName: 'xDai',
        rpcUrls: ['https://dai.poa.network'],
        blockExplorerUrls: ['https://blockscout.com/poa/xdai'],
        nativeCurrency: {
          name: 'xDai',
          symbol: 'XDAI',
          decimals: 18,
        },
      },
    ],
  },
  // 4: {
  //   id: '1',
  //   jsonrpc: '2.0',
  //   method: 'wallet_addEthereumChain',
  //   params: [
  //     {
  //       chainId: '0x4',
  //       chainName: 'Rinkeby',
  //       rpcUrls: ['https://rinkeby.infura.io/v3/6c3a011449f34ccdaaaddfc504d2e1e1'],
  //       blockExplorerUrls: ['https://rinkeby.etherscan.io'],
  //       nativeCurrency: {
  //         name: 'Rinkeby',
  //         symbol: 'ETH',
  //         decimals: 18,
  //       },
  //     },
  //   ],
  // },
  4: {
    id: '1',
    jsonrpc: '2.0',
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: '0x4' }],
  },
};
