import Rekv from 'rekv';

const account = new Rekv({
  balance: {},
  points: {},
  cards: [],
  nftApproved: false,
});

export default account;
