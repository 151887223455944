import React, { useState, useEffect } from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import NumberFormat from 'react-number-format';
import ReactGA from 'react-ga';

import { Formik } from 'formik';
import { InputControl, SubmitButton } from 'formik-chakra-ui';
import * as Yup from 'yup';

import ApproveBtn from '../ApproveBtn';
import store from '../../stores/modal';
import { toast, formatBalance } from '../../utils';
import { dkpoolApi, tokenApi } from '../../api/contracts';
import { useMyBalInfo } from '../../api/query';
import { getPoolAddr } from '../../utils/web3';
import { ToastProps } from '../../utils/types';
import { t } from '../../i18n';

import { MAX_DONATE, MIN_DONATE } from '../../config/constants';

interface FormValues {
  amount: string;
}

const DonateModal = (props: any) => {
  const [isBase, isXL] = useMediaQuery(['(max-width: 768px)', '(min-width: 1280px)']);

  const { tokenName, poolInfo, pointBal = 0, cb = null } = props;
  const { id: poolId } = poolInfo;
  const exchangeRate = formatBalance(poolInfo.exchangeRate);
  const { data = {}, refetch } = useMyBalInfo(tokenName);
  const { bal = '0' } = data;
  const balNum = formatBalance(bal);
  const initialValues: FormValues = { amount: '' };
  const dkpool = dkpoolApi();
  const [loading, setLoading] = useState(false);
  const { donateModalShow } = store.useState('donateModalShow');

  const onClose = () => {
    store.setState({ donateModalShow: false });
  };

  const validationSchema = Yup.object({
    amount: Yup.number()
      .required()
      .typeError(t('mustbenum'))
      .positive()
      .min(MIN_DONATE)
      .max(MAX_DONATE)
      .test(
        'amount',
        t('donate.notenough.error'),
        (value, context) => !!value && value <= Number(balNum),
      ),
  });

  const btnStyle = {
    size: 'lg',
    mt: { base: 4, md: 5 },
    width: '100%',
    fontSize: '20px',
    fontWeight: 'bold',
  };

  const onSub = async (values: any, actions: any) => {
    try {
      const { amount } = values;
      ReactGA.event({
        category: 'donate',
        action: 'donate submit',
        value: amount,
      });
      const toastProps: ToastProps = {
        title: 'Transaction',
        desc: '',
        duration: 6000,
        status: 'success',
      };

      setLoading(true);
      const cbs = {
        receipt: () => {
          setLoading(false);
          onClose();
          refetch();
          toastProps.desc = t('trx.success');
          toast(toastProps);
          if (cb) cb();
          ReactGA.event({
            category: 'donate',
            action: 'donate submit success',
            value: amount,
          });
        },
        error: () => {
          setLoading(false);
          onClose();
          refetch();
        },
      };
      await dkpool.donate(poolId, amount, cbs);
      return null;
    } catch (error) {
      const toastProps = {
        title: '',
        desc: '',
        duration: 6000,
        status: 'error',
      };
      toastProps.title = 'Error';
      toastProps.desc = error.message;
      toastProps.status = 'error';
      toast(toastProps);
      setLoading(false);
      return null;
    }
  };

  const renderBalcne = () => {
    return (
      <Text>
        {t('available')}:{' '}
        <NumberFormat value={balNum} displayType={'text'} thousandSeparator={true} />
      </Text>
    );
  };

  const renderPanel = (num: any) => {
    return (
      <Flex
        width="46%"
        bgColor="borderColor"
        fontSize={{ base: 12, md: 18, xl: 20 }}
        justifyContent="space-between"
        borderRadius="12px"
        textColor="textPrimay"
        fontWeight="bold"
        px={{ base: 3, md: 5 }}
        py={{ base: 1, md: 2.5 }}
      >
        <Box maxWidth="80%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <NumberFormat value={num} displayType={'text'} thousandSeparator={true} />
        </Box>
        <Text textColor="#919191" fontWeight="thin">
          DKP
        </Text>
      </Flex>
    );
  };

  return (
    <Modal isOpen={donateModalShow} onClose={onClose} autoFocus={false} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify="center">
            <Text color="text" fontWeight="bold" textColor="textPrimary">
              {t('donate')}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={{ base: 4, md: 6, xl: 10 }} mt={{ base: '-20px', md: 0 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSub}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, setFieldValue }) => {
              return (
                <Box m="10px auto" as="form" onSubmit={handleSubmit as any}>
                  <Flex
                    fontSize={14}
                    fontWeight={400}
                    textColor="textLable"
                    justifyContent="space-between"
                    mb={2}
                  >
                    <Text>{t('donate.modal.label')}</Text>
                    <Box display={isBase ? 'none' : 'block'}> {renderBalcne()}</Box>
                  </Flex>
                  <Box
                    height={{ base: '42px', md: '48px' }}
                    px={{ base: 1, sm: 4 }}
                    borderRadius="lg"
                    border="1px"
                    borderColor="borderColor"
                  >
                    <InputGroup py={2}>
                      <InputControl
                        inputProps={{
                          fontSize: isBase ? 16 : isXL ? 20 : 18,
                          fontWeight: 'bold',
                          size: isXL ? 'lg' : 'md',
                          variant: 'unstyled',
                          placeholder: t('donate.placeholder'),
                        }}
                        name="amount"
                      />
                      <InputRightElement>
                        <Text
                          as="div"
                          mr={{ base: 0, lg: 4 }}
                          fontSize={{ base: 14, lg: 20 }}
                          color="#919191"
                          fontWeight={400}
                        >
                          {tokenName}
                        </Text>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                  <Box
                    display={isBase ? 'block' : 'none'}
                    textAlign="right"
                    mt="2"
                    fontSize={14}
                    fontWeight={400}
                    textColor="textLable"
                  >
                    {renderBalcne()}
                  </Box>
                  <Box mt={{ base: 0, md: 6 }} mb={{ base: 0, md: 2 }}>
                    <Text fontSize={14} textColor="textLable" mb={{ base: 1, md: 2 }}>
                      {t('dkp.yours')}
                    </Text>
                    <Flex justifyContent="space-between">
                      {renderPanel(pointBal)}
                      <ArrowForwardIcon mt={{ base: 1, md: 3 }} h={{ base: 4, md: 6 }} w="24px" />
                      {renderPanel(
                        +pointBal + Number(Number(exchangeRate) * Number(values.amount)),
                      )}
                    </Flex>
                  </Box>
                  <ApproveBtn
                    {...btnStyle}
                    token={tokenName}
                    contractAddr={getPoolAddr()}
                    button={
                      <SubmitButton isLoading={loading} isDisabled={!!errors.amount} {...btnStyle}>
                        {t('donate').toUpperCase()}
                      </SubmitButton>
                    }
                  />

                  <Text
                    mt={2}
                    mb={4}
                    fontSize={{ base: 12, md: 14 }}
                    fontWeight={400}
                    color="textLable"
                  >
                    {t('donate.modal.tips')}
                  </Text>
                </Box>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DonateModal;
