import React from 'react';
import { Box, Flex, Image, Text, Center, Divider, Tag } from '@chakra-ui/react';

import { formatNumber } from '../../utils';

import riceIcon from '../../assets/rice.png';

interface TokenItemProps {
  network: string;
  balance: string;
}

const tokenItem = ({ network, balance }: TokenItemProps) => {
  return (
    <>
      <Flex flexWrap="wrap" justify="space-between" p={2}>
        <Flex>
          <Image
            w={{ base: '36px', lg: '44px' }}
            h={{ base: '36px', lg: '44px' }}
            borderRadius="50%"
            src={riceIcon}
            mr={4}
          />
          <Flex direction="column" fontFamily="Eurostile">
            <Text
              fontSize={{ base: '14px', lg: '18px' }}
              fontWeight={700}
              color="textPrimary"
              pl={1}
              mb={{ base: 0, lg: 1 }}
            >
              RICE
            </Text>
            <Tag size="sm" backgroundColor="#eee" fontSize="11px" minH="16px">
              {network}
            </Tag>
          </Flex>
        </Flex>

        <Box
          fontFamily="Eurostile"
          fontWeight="bold"
          h={{ base: '36px', lg: '48px' }}
          lineHeight={{ base: '36px', lg: '48px' }}
        >
          <Box as="span">
            {formatNumber(balance).split('.')[0]}
            {formatNumber(balance).split('.')[1] ? '.' : ''}
          </Box>
          <Box as="span" fontSize={{ base: 10, lg: 14 }}>
            {formatNumber(balance).split('.')[1]}
          </Box>
        </Box>
      </Flex>
      <Center height="1px">
        <Divider width="100%" px={8} h="1px" />
      </Center>
    </>
  );
};

export default tokenItem;
