import React, { FC } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';

import themes from '../../themes';

import HeaderContent from './headerContent';
import BottomHeader from './bottomHeader';

export interface HeaderProps {
  sticky?: boolean;
}

const Header: FC<HeaderProps> = ({ sticky }) => {
  const [isMobile, isFullHeader] = useMediaQuery([
    '(max-width: 768px)',
    '(min-width:768px) and (max-width: 992px)',
  ]);

  const head = isMobile ? (
    <BottomHeader />
  ) : isFullHeader ? (
    <Box
      as="header"
      backgroundColor={themes.bgColor}
      position={sticky ? 'fixed' : 'initial'}
      width="100%"
      zIndex={1000}
      background="#fff"
      boxShadow="0px 20px 20px rgba(0, 0, 0, 0.16)"
    >
      <HeaderContent />
    </Box>
  ) : (
    <Box
      position="fixed"
      bg="#fff"
      width={{ base: '100%', lg: 900, xl: 1100, '2xl': 1300 }}
      zIndex={1000}
      borderRadius="0px 0px 12px 12px"
      boxShadow=" 0px 20px 20px rgba(0, 0, 0, 0.16)"
    >
      <HeaderContent />
    </Box>
  );
  return head;
};

export default Header;
