import React from 'react';
import { Stack, Box, Text, Image, Center, Container } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SimpleCard = (props: any) => {
  const { t } = useTranslation();
  const { name, image, amount, cardId, description } = props;

  return (
    <Box>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        border="1px solid #EEEEEE"
        bgColor="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
        boxShadow="0px 4px 6px rgba(123, 70, 0, 0.1)"
        rounded="md"
        overflow="hidden"
      >
        <Center
          pos="relative"
          w="100%"
          objectFit="cover"
          bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
          py={{ base: 2, lg: 0 }}
        >
          <Image maxW="94%" h={{ base: 300, lg: 'auto' }} src={image} />
        </Center>

        <Box
          pos="relative"
          p={2}
          w="100%"
          h={{ base: 220, lg: 420 }}
          textColor="textPrimary"
          borderRadius="0px 0px 8px 8px"
          overflow="hidden"
        >
          <Box textAlign="left">
            <Text fontFamily="Eurostile" fontSize={16} fontWeight="bold" my={1}>
              {name}
            </Text>
          </Box>
          <Container
            h={{ base: 120, lg: 'auto' }}
            fontSize={{ base: 11, lg: 12 }}
            textColor="textDesc"
            lineHeight={{ base: 1.3, xl: 'inherit' }}
            overflow="scroll"
          >
            {description}
          </Container>
          <Text
            pos="absolute"
            bottom={2}
            right={4}
            fontSize={10}
            textColor="textTips"
            textAlign="right"
            m={1}
          >
            {t('balance')}: {amount}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default SimpleCard;
