import React, { useState, useEffect } from 'react';
import {
  Button,
  Badge,
  Stack,
  VStack,
  HStack,
  Flex,
  Box,
  Text,
  Container,
  Tag,
  TagLabel,
  Image,
  Center,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { ToastProps } from '../../utils/types';
import { getConnectedState } from '../../api/query';
import { dkpoolApi } from '../../api/contracts';
import Requirements from './requirements';
import { timeformater, toast, formatBalance } from '../../utils';
import { getPoolAddr } from '../../utils/web3';
import ApproveBtn from '../ApproveBtn';
import poolStore from '../../stores/pools';

const btnStyle = {
  size: 'lg',
  width: '100%',
  height: '38px',
  fontFamily: 'Eurostile',
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: 2,
};

const WinsItem = (props: any) => {
  const dkpool = dkpoolApi();
  const { t } = useTranslation();
  const connected = getConnectedState();
  const [canRedeem, setCanRedeem] = useState(false);
  const [affordable, setAffordable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [btnTip, setBtnTip] = useState('');
  const { pools } = poolStore.useState('pools');
  const {
    name,
    DKP = 0,
    userPoints,
    available,
    image,
    releaseTime,
    closeTime,
    description,
    requirements = {},
    cardId,
    poolId,
    maxSupply,
    totalSupply,
    expandPools = [],
    expandSpendingDKP = [],
    currentUserPoints,
    userCards = [],
    cb = null,
    redeemCount,
  } = props;

  const dkpArr: any = [];

  expandPools.map((id: number, idx: number) => {
    dkpArr.push({ id, balance: expandSpendingDKP[idx] });
    return null;
  });
  if (DKP > 0) {
    dkpArr.push({ id: poolId, balance: DKP });
  }

  const getRedeemStatus = () => {
    if (redeemCount >= 1) {
      setCanRedeem(false);
      setBtnTip('acquired');
      return;
    }

    if (expandPools.length === 0) {
      setCanRedeem(affordable);
    } else {
      const flag = dkpArr.some((pool: any, idx: number) => {
        const { id, balance } = pool;
        const bal = formatBalance(balance);
        return Number(currentUserPoints[id]) < Number(bal);
      });
      setCanRedeem(!flag);
    }
  };

  // time stamp determine
  useEffect(() => {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);
    let flag = true;
    if (releaseTime && timestamp < releaseTime) {
      flag = false;
    }
    if (closeTime && timestamp > closeTime) {
      flag = false;
    }
    setCanRedeem(flag);
  }, [releaseTime, closeTime]);

  useEffect(() => {
    const flag = Number(userPoints) >= Number(formatBalance(DKP));
    setAffordable(flag);
  }, [userPoints, currentUserPoints]);

  useEffect(() => {
    getRedeemStatus();
  }, [currentUserPoints, userPoints]);

  useEffect(() => {
    if (redeemCount >= 1) {
      setCanRedeem(false);
      setBtnTip('acquired');
    }
  }, [redeemCount]);

  const handleCheck = (isSatisfy: boolean) => {
    setCanRedeem(isSatisfy);
  };

  const redeem = async () => {
    ReactGA.event({
      category: 'redeem',
      action: 'redeem wins',
    });

    try {
      if (!connected) return null;
      const toastProps: ToastProps = {
        title: 'Transaction',
        desc: '',
        status: 'success',
      };

      setLoading(true);
      const cbs = {
        receipt: () => {
          setLoading(false);
          toastProps.desc = t('trx.success');
          toast(toastProps);
          ReactGA.event({
            category: 'redeem',
            action: 'redeem wins successful',
            value: cardId,
          });
          if (cb) {
            setTimeout(() => {
              cb();
            }, 2000);
          }
        },
        error: () => {
          setLoading(false);
        },
      };
      await dkpool.redeem(poolId, cardId, cbs);
      return null;
    } catch (error) {
      const toastProps = {
        title: '',
        desc: '',
        duration: 6000,
        status: 'info',
      };
      toastProps.title = 'Error';
      toastProps.desc = error.message;
      toastProps.status = 'error';
      toast(toastProps);
      setLoading(false);
      return null;
    }
  };

  const renderDKPS = () => {
    const lable = 'DKP';

    return (
      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        mb={2}
        overflow="hidden"
        h="42px"
        w="100%"
      >
        {dkpArr.map((pool: any, idx: number) => {
          const { id, balance, name: poolName } = pool;
          const bal = formatBalance(balance, 18);
          const afford = Number(currentUserPoints[id]) > Number(bal);
          return (
            <HStack w="100%" justify="space-between" key={idx}>
              <Center>
                <Tag
                  size="sm"
                  maxW="78px"
                  key={idx}
                  borderRadius="full"
                  variant="solid"
                  bgColor="primary"
                  minH="16px"
                  fontFamily="Eurostile"
                >
                  <ArrowForwardIcon
                    boxSize="12px"
                    borderRadius="full"
                    bgColor="white"
                    color="primary"
                    marginLeft="-6px"
                  />
                  <TagLabel ml={1} fontSize={10} fontWeight="bold">{`${lable}${id}`}</TagLabel>
                </Tag>

                <Box
                  ml={2}
                  as="span"
                  fontFamily="Eurostile"
                  fontSize="14px"
                  textColor={afford ? 'textPrimary' : 'error'}
                  fontWeight="bold"
                >
                  <NumberFormat value={bal} displayType={'text'} thousandSeparator={true} />
                </Box>
              </Center>

              <Text
                maxW="100px"
                textAlign="right"
                fontSize={10}
                fontWeight={400}
                textColor="#CCCCCC"
              >
                {pools[id].name}
              </Text>
            </HStack>
          );
        })}
      </Flex>
    );
  };

  return (
    <Box p={2} maxW="648px">
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        border="1px solid #EEEEEE"
        bgColor="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
        boxShadow="0px 4px 6px rgba(123, 70, 0, 0.1)"
        rounded="md"
        overflow="hidden"
      >
        <Center
          pos="relative"
          w="100%"
          objectFit="cover"
          bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
          py={{ base: 2, lg: 0 }}
        >
          <Image maxW="94%" h={{ base: 280, lg: 'auto' }} src={image} />
        </Center>

        <VStack w="100%" h={{ base: 320, lg: 420 }} p={2} pl={{ base: 2, lg: 0 }}>
          <Flex width="100%" justify="space-between">
            <Text
              as="div"
              fontFamily="Eurostile"
              fontSize={16}
              fontWeight="bold"
              my={1}
              textAlign="left"
              lineHeight="normal"
            >
              {name}
            </Text>
            <Badge
              mt={1}
              size="sm"
              h="16px"
              borderRadius="20px"
              fontFamily="Eurostile"
              fontSize={10}
              bgColor="primary"
              textColor="white"
            >{`${maxSupply - totalSupply}/${maxSupply}`}</Badge>
          </Flex>

          <Container
            h={{ base: 140, lg: 180 }}
            fontSize={{ base: 11, lg: 12 }}
            textColor="textDesc"
            lineHeight={{ base: 1.3, xl: 'inherit' }}
            overflow="hidden"
          >
            {description}
          </Container>
          <Requirements
            requirements={requirements}
            userCards={userCards}
            onCheck={(isSatisfy: boolean) => handleCheck(isSatisfy)}
          />

          <Box w="100%" textColor="textPrimary">
            <Box textAlign="left" px="5px" h="50px">
              <Flex justifyContent="space-between">
                {expandPools.length === 0 ? (
                  <Flex flexDirection="column" justifyContent="flex-end" mb={2} h="42px" w="100%">
                    <HStack w="100%" justify="space-between">
                      <Center>
                        <Tag
                          size="sm"
                          maxW="78px"
                          borderRadius="full"
                          variant="solid"
                          bgColor="primary"
                          minH="16px"
                        >
                          <ArrowForwardIcon
                            boxSize="12px"
                            borderRadius="full"
                            bgColor="white"
                            color="primary"
                            marginLeft="-6px"
                          />
                          <TagLabel
                            ml={1}
                            fontFamily="Eurostile"
                            fontSize={10}
                            fontWeight="bold"
                          >{`DKP${poolId}`}</TagLabel>
                        </Tag>

                        <Box
                          ml={2}
                          as="span"
                          fontFamily="Eurostile"
                          fontSize="14px"
                          fontWeight="bold"
                          textColor={affordable ? 'textPrimary' : 'error'}
                        >
                          <NumberFormat
                            value={formatBalance(DKP)}
                            displayType={'text'}
                            thousandSeparator={true}
                          />
                        </Box>
                      </Center>
                    </HStack>
                  </Flex>
                ) : (
                  renderDKPS()
                )}
              </Flex>
            </Box>

            <ApproveBtn
              {...btnStyle}
              isNFTs={true}
              contractAddr={getPoolAddr()}
              cb={cb}
              button={
                <Button
                  isLoading={loading}
                  disabled={!affordable || !canRedeem || totalSupply === maxSupply}
                  onClick={() => redeem()}
                  {...btnStyle}
                >
                  {btnTip
                    ? t(btnTip).toUpperCase()
                    : t(totalSupply === maxSupply ? 'soldout' : 'win').toUpperCase()}
                </Button>
              }
            />

            {/* <Text fontSize={10} textColor="textTips" textAlign="center" m={1}>
            {`${timeformater(startTime)} ~ ${timeformater(endTime)}`}
          </Text> */}
          </Box>
        </VStack>
      </Stack>
    </Box>
  );
};

export default WinsItem;
