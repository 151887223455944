import React from 'react';
import {
  Box,
  SimpleGrid,
  Accordion,
  AccordionItem,
  Flex,
  Avatar,
  AccordionButton,
  AccordionPanel,
  Text,
  AccordionIcon,
  useTheme,
  HStack,
  VStack,
  Button,
  IconButton,
  Image,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useBreakpointValue,
} from '@chakra-ui/react';
import ReactGA from 'react-ga';

import { useTranslation } from 'react-i18next';
import Cards from '../cards/index';

import Title from '../title';
import gift from '../../assets/gift.svg';
import { formatNumber } from '../../utils';

const PoolItem = (props: any) => {
  const btnSize = useBreakpointValue({ base: 'md', md: 'sm', xl: 'md' });
  const {
    poolInfo,
    token,
    pointBal,
    isDonate = false,
    openModal = null,
    cb = null,
    userPoints,
    ...rest
  } = props;
  const { t } = useTranslation();

  const handleBtn = (e: any) => {
    e.preventDefault();
    ReactGA.event({
      category: 'ovo',
      action: 'click donate btn',
    });
    if (openModal) {
      openModal();
    }
  };

  const handleChange = (idx: number) => {
    if (idx >= 0) {
      ReactGA.modalview('/ovo/show-redeem');
    }
  };

  return (
    <Box>
      <Accordion {...rest} allowToggle onChange={(idx: number) => handleChange(idx)}>
        <AccordionItem border="none" _hover={{ bg: '#fff' }} mb="-16px">
          {({ isExpanded }) => (
            <>
              <AccordionButton borderRadius="12px" textAlign="left" d="block">
                <Flex flexWrap="wrap" justify="space-between">
                  <HStack spacing={{ base: '8px', '2xl': '16px' }} mr={{ base: 0, xl: 8 }}>
                    <Box w={{ base: '32px', md: '42px', xl: '48px' }}>
                      <Image
                        w="100%"
                        src={poolInfo.icon}
                        borderRadius={10}
                        boxShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
                      />
                    </Box>
                    <Box minW="216px">
                      <Title title={poolInfo.title} desc={poolInfo.desc} />
                    </Box>
                  </HStack>

                  <StatGroup
                    d="flex"
                    alignItems="center"
                    textAlign="left"
                    whiteSpace="nowrap"
                    mt={{ base: 1, md: 2, lg: 0 }}
                    ml={{ base: 10, md: 3 }}
                  >
                    <Stat
                      mr={{ base: 0, lg: 4 }}
                      w={{ base: '204px', md: '120px', lg: '132px', xl: '204px' }}
                    >
                      <StatLabel fontSize={{ base: 11, lg: 12 }}>
                        {isDonate ? t('donate.total') : t('token.title')} {token.name}
                      </StatLabel>
                      <StatNumber
                        fontSize={{ base: 12, lg: 18 }}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        fontFamily="Eurostile"
                      >
                        <Image
                          w={{ base: '16px', md: '20px', lg: '24px' }}
                          h={{ base: '16px', md: '20px', lg: '24px' }}
                          src={token.icon}
                          position="absolute"
                          left={{ base: '-26px', lg: '-32px', xl: '-36px' }}
                        />
                        <Box as="span">
                          {formatNumber(token.balance).split('.')[0]}
                          {formatNumber(token.balance).split('.')[1] ? '.' : ''}
                        </Box>
                        <Box as="span" fontSize={{ base: 10, lg: 14 }}>
                          {formatNumber(token.balance).split('.')[1]}
                        </Box>
                      </StatNumber>
                    </Stat>
                    <Stat w={{ base: '204px', md: '120px', lg: '132px', xl: '204px' }}>
                      <StatLabel fontSize={{ base: 11, lg: 12 }}>
                        {t(isDonate ? 'dkp' : 'points')}
                      </StatLabel>
                      <StatNumber fontSize={{ base: 12, lg: 18 }} fontFamily="Eurostile">
                        <Box as="span">
                          {formatNumber(userPoints).split('.')[0]}
                          {formatNumber(userPoints).split('.')[1] ? '.' : ''}
                        </Box>
                        <Box as="span" fontSize={{ base: 10, lg: 14 }}>
                          {formatNumber(userPoints).split('.')[1]}
                        </Box>
                      </StatNumber>
                    </Stat>
                  </StatGroup>
                  <Box width={{ base: '100%', md: 'auto' }} mt={{ base: 4, md: 0 }}>
                    <Flex align="center" h="100%">
                      <Button
                        size={btnSize}
                        fontFamily="Eurostile"
                        fontSize="18px"
                        fontWeight="bold"
                        mr="12px"
                        px={8}
                        flexGrow={{ base: 1, md: 'inherit' }}
                        onClick={(e) => handleBtn(e)}
                      >
                        {t(isDonate ? 'donate' : 'stake').toUpperCase()}
                      </Button>
                      <IconButton
                        size={btnSize}
                        variant="outline"
                        aria-label="opt"
                        borderRadius="lg"
                        colorScheme="iconBtn"
                        icon={isExpanded ? <AccordionIcon /> : <Image w="20px" src={gift} />}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Cards
                  userPoints={userPoints}
                  poolId={poolInfo.id}
                  poolInfo={poolInfo}
                  cb={cb}
                  isExpanded
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default PoolItem;
