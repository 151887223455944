import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { useUserNFT, getAddrState } from '../../api/query';
import Card from './cardSimple';
import Wins from './cardWins';
import { WINS_POOL_ID } from '../../config/constants';
import { dkpoolApi } from '../../api/contracts';

const Cards = (props: any) => {
  const { cb = null, isPerks = false } = props;
  const address = getAddrState();
  const pool = dkpoolApi();
  const [winsIds, setIds] = useState([]);
  const fetchWinsId = async () => {
    const ids = (await pool.getCards(WINS_POOL_ID)) || [];
    setIds(ids);
  };

  useEffect(() => {
    fetchWinsId();
  }, []);
  const { data = {} } = useUserNFT(address);
  const { cards = [] } = data;
  const NFT = isPerks ? Wins : Card;
  const width = isPerks
    ? { base: '368px', md: '50%' }
    : { base: '368px', md: '50%', lg: '33.333%', '2xl': '25%' };
  const colsConfig = isPerks ? [1, 1, 1, 1, 2] : [1, 1, 2, 3, 4];
  return (
    <Flex wrap="wrap" justify={{ base: 'center', md: 'flex-start' }}>
      {cards.map((card: any, idx: number) => {
        const { amount, cardId } = card;
        const showFlag = isPerks ? winsIds.indexOf(cardId) >= 0 : winsIds.indexOf(cardId) < 0;
        if (amount > 0 && showFlag) {
          return (
            <Box width={width}>
              <Box p={2}>
                <NFT {...card} key={idx} cb={cb} />
              </Box>
            </Box>
          );
        }
        return null;
      })}
    </Flex>
  );
};

export default Cards;
