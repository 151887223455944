const obj = {
  primary: '#00D392',
  secondary: '#00E0B5',
  textPrimary: '#444444',
  highlight: '#05C1C1',
  textLink: '#46367E',
  textDesc: '#666666',
  textTips: '#999999',
  borderColor: '#EEEEEE',
  error: '#BF0404',
  tipBg: 'FFF7F2',
  textLable: '#707070',
  iconBtn: {
    50: '#EAFFFB',
    600: '#00D392',
  },
};

export default obj;
