import React, { useState, useEffect } from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Tabs,
  TabList,
  Tab,
  Flex,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react';
import ReactGA from 'react-ga';

import { Formik } from 'formik';
import NumberFormat from 'react-number-format';

import { InputControl, SubmitButton } from 'formik-chakra-ui';
import * as Yup from 'yup';
import { globalStore } from 'rekv';

import { dkpoolApi } from '../../api/contracts';
import { useMyBalInfo } from '../../api/query';
import { getPoolAddr, convertAmount } from '../../utils/web3';
import { toast, formatBalance } from '../../utils';
import { ToastProps } from '../../utils/types';

import { t } from '../../i18n';
import { MAX_STAKE, MIN_STAKE } from '../../config/constants';

import ApproveBtn from '../ApproveBtn';

interface FormValues {
  amount: string;
}

const StakeModal = (props: any) => {
  const [isBase, isXL] = useMediaQuery(['(max-width: 768px)', '(min-width: 1280px)']);

  const { isOpen, onClose, cb = null, type, poolInfo: info = {} } = props;
  const {
    id,
    token: { name: tokenName },
    name,
    poolInfo = {},
    pointName,
  } = info;
  const { decimals: precision = '', maxStake = 0 } = poolInfo;
  const decimals = precision.length - 1;
  const maxNum = formatBalance(maxStake, decimals);
  const initialValues: FormValues = { amount: '' };
  const [tabIndex, setTabIndex] = React.useState(0);
  const [formType, setFormType] = React.useState('stake');
  const { appState } = globalStore.useState('appState');

  const isStake = formType === 'stake';
  const colorScheme = isStake ? 'grass' : 'reddish';

  const { data = {}, refetch } = useMyBalInfo(tokenName, id);
  const { bal = 0, staked = 0 } = data;
  const balNum = formatBalance(bal, decimals);
  const stakedNum = formatBalance(staked, decimals);
  const validationSchema = Yup.object({
    amount: Yup.number()
      .required()
      .typeError(t('mustbenum'))
      .positive()
      .max(MAX_STAKE)
      .min(MIN_STAKE)
      .test('amount', t('notenough'), (value) => {
        const balance = isStake ? balNum : stakedNum;
        return !!value && value <= Number(balance);
      })
      .test('amount', t('staking.max.error'), (value = 0) => {
        if (!isStake) {
          return true;
        }
        const balance = Number(stakedNum) + value;
        return !!balance && balance <= Number(maxNum);
      }),
  });
  // const [txInfo, setTx] = useState()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTabIndex(type === 'stake' ? 0 : 1);
    setFormType(type);
  }, [type, isOpen]);

  const handleTabsChange = (index: number) => {
    const tabType = index === 0 ? 'stake' : 'withdraw';
    setTabIndex(index);
    setFormType(tabType);
    ReactGA.event({
      category: tabType,
      action: 'change tab',
    });
  };

  const tabStyle = (idx: number) => {
    // const color = isStake ? themes.colors.primary : themes.colors.secondary;

    const styles = {
      borderBottomColor: 'transparent',
      fontSize: '20px',
      textColor: 'textPrimary',
      fontWeight: 'bold',
      marginBottom: '2px',
      border: 'none',
      borderRadius: '12px',
      bgColor: tabIndex === idx ? 'white' : 'borderColor',
      _focus: {
        boxShadow: 'none',
      },
    };
    return styles;
  };

  const btnStyle = {
    size: 'lg',
    mt: { base: 2, md: 6 },
    width: '100%',
    fontSize: '20px',
    fontWeight: 'bold',
  };

  const onSub = async (values: any, actions: any) => {
    try {
      const { amount } = values;
      ReactGA.event({
        category: formType,
        action: `${formType} fund`,
        value: amount,
      });
      const dkpool = dkpoolApi();
      const toastProps: ToastProps = {
        title: 'Transaction',
        desc: '',
        status: 'success',
      };
      setLoading(true);
      const cbs = {
        receipt: () => {
          toastProps.desc = t(`${formType}.success`);
          toast(toastProps);
          setLoading(false);
          ReactGA.event({
            category: formType,
            action: `${formType} fund success`,
            value: amount,
          });
          refetch();
          onClose();
          if (cb) {
            cb();
          }
        },
        error: () => {
          setLoading(false);
          onClose();
          refetch();
        },
      };
      const amountNum = convertAmount(amount, decimals);
      await dkpool[formType](id, amountNum, cbs);
      return null;
    } catch (error) {
      // console.log(error);
      const toastProps = {
        title: '',
        desc: '',
        duration: 6000,
        status: 'info',
      };
      toastProps.title = 'Error';
      toastProps.desc = error.message;
      toastProps.status = 'error';
      toast(toastProps);
      setLoading(false);
      return null;
    }
    // fetchData()
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={0}>
          <Tabs variant="unstyled" index={tabIndex} isFitted onChange={handleTabsChange}>
            <TabList>
              <Tab {...tabStyle(0)}>{t('stake')}</Tab>
              <Tab {...tabStyle(1)}>{t('withdraw')}</Tab>
            </TabList>
          </Tabs>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={{ base: 4, md: 6, xl: 10 }} pt={6}>
          <Flex fontSize={14} color="textLable" justifyContent="space-between" mb={2}>
            <Text>
              {isStake
                ? t('stake.tip', { token: tokenName, name: pointName })
                : t('withdraw.tip', { token: tokenName })}
            </Text>
            <Box display={isBase ? 'none' : 'block'}>
              <Text>
                {t(isStake ? 'available' : 'your.stake')} :{' '}
                <NumberFormat
                  value={isStake ? balNum : stakedNum}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </Text>
            </Box>
          </Flex>

          <Formik
            initialValues={initialValues}
            onSubmit={onSub}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, setFieldValue }) => (
              <Box as="form" onSubmit={handleSubmit as any}>
                <Box
                  height={{ base: '42px', md: '48px' }}
                  px={{ base: 1, sm: 4 }}
                  borderRadius="lg"
                  border="1px"
                  borderColor="borderColor"
                >
                  {/* {renderLable(values)} */}
                  <InputGroup>
                    <InputControl
                      inputProps={{
                        py: 2,
                        fontSize: isBase ? 16 : isXL ? 20 : 18,
                        fontWeight: 'bold',
                        size: isXL ? 'lg' : 'md',
                        variant: 'unstyled',
                      }}
                      name="amount"
                    />
                    <InputRightElement>
                      <Text
                        as="div"
                        mr={{ base: 0, lg: 8 }}
                        fontSize={{ base: 14, lg: 20 }}
                        color="#919191"
                        fontWeight={400}
                      >
                        {tokenName}
                      </Text>
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <Box
                  display={isBase ? 'block' : 'none'}
                  textAlign="right"
                  mt={2}
                  fontSize={14}
                  color="textLable"
                >
                  <Text>
                    {t(isStake ? 'available' : 'your.stake')} :{' '}
                    <NumberFormat
                      value={isStake ? balNum : stakedNum}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                  </Text>
                </Box>
                <ApproveBtn
                  {...btnStyle}
                  token={tokenName}
                  contractAddr={getPoolAddr()}
                  button={
                    <SubmitButton isLoading={loading} {...btnStyle} isDisabled={!!errors.amount}>
                      {t(formType).toUpperCase()}
                    </SubmitButton>
                  }
                />
              </Box>
            )}
          </Formik>
          <Text fontSize={{ base: 12, md: 14 }} mt={2} mb={4} color="textLable">
            {t('withdraw.anytime')}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StakeModal;
