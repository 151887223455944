import React from 'react';
import { VStack, Box, Text, Image, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SimpleCard = (props: any) => {
  const { t } = useTranslation();
  const { name, image, amount } = props;

  return (
    <VStack
      h="480px"
      border="1px solid #EEEEEE"
      maxW="320px"
      boxShadow="0px 4px 6px rgba(123, 70, 0, 0.1)"
      rounded="md"
      overflow="hidden"
    >
      <Center
        pos="relative"
        w="100%"
        h="425px"
        objectFit="cover"
        bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
      >
        <Image src={image} />
      </Center>
      <Box w="100%" h="60px" textColor="textPrimary" borderRadius="0px 0px 8px 8px" px="10px">
        <Box textAlign="left" px="5px">
          <Text
            fontFamily="Eurostile"
            fontSize={16}
            fontWeight="bold"
            mb={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {name}
          </Text>
        </Box>

        <Text fontSize={10} textColor="textTips" textAlign="right" m={1}>
          {t('balance')}: {amount}
        </Text>
      </Box>
    </VStack>
  );
};

export default SimpleCard;
