import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import ReactGA from 'react-ga';

import StakePanel from 'components/stake';
// import {

// } from '../../api/contracts';
// import { useAssetsQuery } from '../../api/query';

const Page = () => {
  useEffect(() => {
    ReactGA.pageview('/pools');
  }, []);

  return (
    <Box>
      <StakePanel isSlice={false} />
    </Box>
  );
};

export default Page;
