import React, { useEffect } from 'react';
import { Box, Text, Image, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@chakra-ui/icons';
import { getNFTAddr } from '../../utils/web3';
// import { useTranslation } from 'react-i18next';

const Requirements = (props: any) => {
  const { t } = useTranslation();
  const { requirements = [], onCheck, userCards = [] } = props;
  const getRedeemState = () => {
    const flag = userCards.some((card: any) => {
      const { cardId } = card;
      const index = Object.keys(requirements).indexOf(cardId);
      return index < 0;
    });
    onCheck(!flag);
  };

  useEffect(() => {
    if (userCards.length === 0 && Object.keys(requirements).length > 0) {
      onCheck(false);
    }
    // requirements.filter
    getRedeemState();
  }, [requirements, userCards]);

  const renderRequireNFT = (nft: any) => {
    const { image, id, amount: num } = nft;
    const available = userCards.some((card: any) => {
      const { cardId, amount } = card;
      return cardId === id && amount >= num;
    });
    if (!available) {
      onCheck(false);
    }
    return (
      <Box pos="relative">
        <Image
          mr={3}
          border="1px solid "
          borderColor={available ? 'secondary' : '#CCCCCC'}
          borderRadius="23px"
          h="46px"
          w="46px"
          src={image}
        />
        <CheckIcon
          bottom={-1}
          left={4}
          pos="absolute"
          h="10px"
          w="10px"
          backgroundColor={available ? 'secondary' : '#CCCCCC'}
          color="white"
          borderRadius="full"
          p="0.5"
        />
      </Box>
    );
  };

  return (
    <Flex flexDirection="column" justifyContent="flex-end" w="100%" h="170px">
      <Text mb={2} fontSize={{ base: 11, lg: 12 }} fontWeight={400} textColor="textPrimary">
        {t('requirement')}
      </Text>
      <Flex>
        {Object.keys(requirements).map((id: any) => {
          return renderRequireNFT(requirements[id]);
        })}
      </Flex>
    </Flex>
  );
};

export default Requirements;
