import React, { useEffect } from 'react';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { globalStore } from 'rekv';

import { queryCardsWithPoolId } from '../../api/query';
import CardItem from './cardItem';
import { t } from '../../i18n';
import accountStore from '../../stores/account';

const Cards = (props: any) => {
  const { poolInfo = {}, cb = null, isExpanded = false, userPoints = 0 } = props;
  // console.log(poolInfo, 'poolInfo。。。。。。。。')
  const { id = 0 } = poolInfo;
  const { points: currentUserPoints } = accountStore.useState('points');
  const { data = {}, refetch, isLoading } = queryCardsWithPoolId(id);
  const { disableCards = [] } = globalStore.useState('disableCards');

  let { cards = [] } = data;

  cards = cards.filter((card: any) => {
    if (disableCards.indexOf(Number(card.cardId)) >= 0) {
      return false;
    }
    return true;
  });
  useEffect(() => {
    if (isExpanded && cards.length === 0 && !isLoading) {
      refetch();
    }
  }, [isExpanded, cards, isLoading]);

  const renderPlaceHolder = () => {
    const style = {
      maxW: '320px',
      minW: '300px',
      height: '480px',
    };
    if (isLoading) {
      return [
        <Skeleton {...style} key={1} />,
        <Skeleton {...style} key={2} />,
        <Skeleton {...style} key={3} />,
        <Skeleton {...style} key={4} />,
      ];
    }
    return <Text>{t('empty')}</Text>;
  };

  return (
    <Flex wrap={{ base: 'nowrap', md: 'wrap' }} justify="flex-start" overflow="scroll">
      {cards.length > 0
        ? cards.map((card: any, idx: number) => {
            let cardItem = card;
            if (id === 1) {
              cardItem = { ...card, userPoints };
            }
            return (
              <Box
                width={{ base: '320px', md: '50%', lg: '33.333%', '2xl': '25%' }}
                minW={{ base: '320px', md: 0 }}
                key={idx}
              >
                <Box p={2}>
                  <CardItem {...card} key={idx} cb={cb} currentUserPoints={currentUserPoints} />
                </Box>
              </Box>
            );
          })
        : renderPlaceHolder()}
    </Flex>
  );
};

export default Cards;
