import React from 'react';
// import { groupBy } from 'ramda';
import { Box } from '@chakra-ui/react';
// import { useTranslation } from 'react-i18next';
// import { useQuery } from 'react-query';
// import { globalStore } from 'rekv';
import OVOPanel from 'components/ovo';
import StakePanel from 'components/stake';
import RedeemPanel from 'components/redeem';
import WinsPanel from 'components/wins';
// import {

// } from '../../api/contracts';
// import { useAssetsQuery } from '../../api/query';

const Page = () => {
  return (
    <Box>
      <OVOPanel />
      <StakePanel isSlice />
      <RedeemPanel />
      <WinsPanel />
    </Box>
  );
};

export default Page;
