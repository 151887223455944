import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getConnectedState } from '../../api/query';
import Title from '../title';
import Panel from '../panel';
import Roles from '../roles';

const WinsPannel = () => {
  // const { appState = {} } = globalStore.useState('appState')
  // const { connected = false } = appState
  const connected = getConnectedState();
  const { t } = useTranslation();

  return (
    <Panel mt={{ base: 2, lg: 4 }} py={4}>
      <Title title={t('wins.title')} desc={t('wins.desc')}></Title>
      <Box mt={4}>
        {connected && <Roles />}
        {/* <Coming /> */}
      </Box>
    </Panel>
  );
};

export default WinsPannel;
