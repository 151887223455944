import React from 'react';
import { Flex, Box, Skeleton, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { globalStore } from 'rekv';

import { useOVOInfo, getConnectedState, useConfig } from '../../api/query';
import Title from '../title';
import Panel from '../panel';
import Cards from '../cards/redeemList';
import ConnectTip from '../connectTip';

const RedeemPannel = () => {
  const connected = getConnectedState();
  const { t } = useTranslation();

  const { redeemConfig } = globalStore.useState('redeemConfig');

  const props = {};

  const renderPlaceHolder = () => {
    const style = {
      maxW: '320px',
      minW: '300px',
      height: '480px',
    };
    return (
      <SimpleGrid columns={[1, 1, 2, 2, 4]} spacing={4}>
        <Skeleton {...style} key={1} />
        <Skeleton {...style} key={2} />
        <Skeleton {...style} key={3} />
        <Skeleton {...style} key={4} />
      </SimpleGrid>
    );
  };

  return (
    <Panel mt={{ base: 2, lg: 4 }} py={4}>
      <Title title={t('redeem.title')} desc={t('redeem.desc')}></Title>
      {connected ? <Cards config={redeemConfig} /> : <ConnectTip />}
    </Panel>
  );
};

export default RedeemPannel;
