import React from 'react';
import {
  Box,
  Flex,
  Text,
  Center,
  Divider,
  Link,
  LinkBox,
  LinkOverlay,
  Image,
} from '@chakra-ui/react';
import Panel from '../panel';
import twitter from '../../assets/twitter.svg';
import medium from '../../assets/medium.svg';
import discord from '../../assets/discord.svg';
import telegram from '../../assets/telegram.svg';

export default function Footer() {
  return (
    <Box as="footer" pb={8} mx="auto" width={{ base: '100%', lg: 900, xl: 1100, '2xl': 1300 }}>
      <Panel>
        <Flex direction="column" alignItems="center">
          <Text fontSize="24px" mb={1}>
            DAOSquare
          </Text>
          <Text fontSize="14px">Incubator for Web3.0</Text>
          <Flex my={4}>
            <Link href="https://www.daosquare.io/" isExternal mx={{ base: 1, sm: 3 }}>
              Website
            </Link>
            <Link href="https://forum.daosquare.io/" isExternal mx={{ base: 1, sm: 3 }}>
              Forum
            </Link>
            <Link href="https://bistro.daosquare.io/#/?ref=dkp" isExternal mx={{ base: 1, sm: 3 }}>
              Bistro
            </Link>
            <Link href="https://medium.com/daosquare" isExternal mx={{ base: 1, sm: 3 }}>
              Medium
            </Link>
            <Link href="mailto:contact@daosquare.io" isExternal mx={{ base: 1, sm: 3 }}>
              Contact
            </Link>
          </Flex>
          <Flex mb={8}>
            <LinkBox mx={4}>
              <LinkOverlay href="https://twitter.com/DAOSquare" isExternal>
                <Image w="32px" h="auto" src={twitter} />
              </LinkOverlay>
            </LinkBox>
            <LinkBox mx={4}>
              <LinkOverlay href="https://discord.gg/JngTE8xMgX" isExternal>
                <Image w="32px" h="auto" src={discord} />
              </LinkOverlay>
            </LinkBox>
            <LinkBox mx={4}>
              <LinkOverlay href="https://medium.com/daosquare" isExternal>
                <Image w="32px" h="auto" src={medium} />
              </LinkOverlay>
            </LinkBox>
            <LinkBox mx={4}>
              <LinkOverlay href="https://t.me/DAOSquareOfficial" isExternal>
                <Image w="32px" h="auto" src={telegram} />
              </LinkOverlay>
            </LinkBox>
          </Flex>
          <Center w="80%" px={8}>
            <Divider />
          </Center>
          <Text fontSize="14px" my={4}>
            @2021 DAOSquare All Rights Reserved
          </Text>
        </Flex>
      </Panel>
    </Box>
  );
}
