import React from 'react';
import { Box } from '@chakra-ui/react';

const Page = () => {
  return (
    <Box textAlign="center" py={10}>
      404
    </Box>
  );
};

export default Page;
